(function(i, p, w, d, b, $, c, t){
    var TNT_Pencil_Ad = {
        'el': {
            'ad_wrapper': '#tn-pencil-container',
            'ad_main': '.tn-pencil',
            'ad_strip': '.tn-pencil-strip',
            'ad_billboard': '.tn-pencil-billboard',
            'ad_toggle': '.tn-pencil-toggle',
            'ad_closed': '.tn-pencil-closed',
            'ad_open': '.tn-pencil-open'
        },
        'ad_html': function() {
            var html = '';
            html += '<style>';
                if (this.settings.pencil || this.settings.pencil_mobile) {
                    html += '#fixed-impact-top { margin-bottom: 0; }' +
                    this.el.ad_wrapper + ' { background-color: transparent; text-align: center; z-index: 1; position: relative; }';
                    if (this.settings.pencil_background_color) {
                        html += this.el.ad_wrapper + ' { background-color: #' + this.settings.pencil_background_color.replace('#','') + '; }';
                    }
                    html += this.el.ad_wrapper + ' img { margin: 0 auto; }' +
                    this.el.ad_wrapper + ' ' + this.el.ad_main + ' { padding: 0; }' +
                    this.el.ad_wrapper + ' ' + this.el.ad_strip + ' { position: relative; }' +
                    this.el.ad_wrapper + ' ' + this.el.ad_billboard + ' { max-height: 0; overflow: hidden; -webkit-transition: max-height 0.8s; -moz-transition: max-height 0.8s; transition: max-height 0.8s; }' +
                    this.el.ad_wrapper + '.in ' + this.el.ad_billboard + ' { max-height: 500px; }' +
                    this.el.ad_wrapper + ' ' + this.el.ad_toggle + ' { position: absolute; font-family: arial; color: #000; font-size: 14px; line-height: 50px; height: 50px; font-weight: bold; right:0; width: 100px; text-align: center; cursor: pointer; }';
                    if (this.settings.pencil_button_color) {
                        html += this.el.ad_wrapper + ' ' + this.el.ad_toggle + ' { color: #' + this.settings.pencil_button_color.replace('#','') + '; }';
                    }
                    html += this.el.ad_wrapper + ' ' + this.el.ad_toogle + ':hover { opacity: 0.8; }' +
                    this.el.ad_wrapper + ' ' + this.el.ad_toggle + ' .tnt-svg { opacity: 0.5; }' +
                    this.el.ad_wrapper + ' ' + this.el.ad_closed + ' { display: block; position: relative; top: 50%; left: 50%; transform: translate(-50%, -50%); }' +
                    this.el.ad_wrapper + '.in ' + this.el.ad_closed + ' { display: none; }' +
                    this.el.ad_wrapper + ' ' + this.el.ad_open + ' { display: none; position: relative; top: 50%; left: 50%; transform: translate(-50%, -50%); }' +
                    this.el.ad_wrapper + '.in ' + this.el.ad_open + ' { display: block; }' +
                    '.drawer-open ' + this.el.ad_wrapper + ' { display: none; }' +
                    '@media (max-width: 1199px) {' +
                        this.el.ad_wrapper + ' ' + this.el.ad_toggle + ' { line-height: 41px; height: 41px; width: 84px; }' +
                    '}' +
                    '@media (max-width: 991px) {' +
                        this.el.ad_wrapper + ' ' + this.el.ad_toggle + ' { line-height: 32px; height: 32px; width: 65px; }' +
                        this.el.ad_wrapper + ' ' + this.el.ad_toggle + ' .tnt-svg { display: none; }' +
                    '}'+
                    '@media screen and (max-width: 767px) {' +
                        this.el.ad_wrapper + ' ' + this.el.ad_toggle + ' { width: auto; height: 100%; padding: 3px 8px; bottom: 0; }' +
                        this.el.ad_wrapper + ' ' + this.el.ad_toggle + ' .tnt-svg { display: block; font-size: 25px; }' +
                        this.el.ad_wrapper + ' ' + this.el.ad_toggle + ' .x-pencil-toggle-text { display: none; }' +
                    '}';
                }
            html += '</style>';
            if (this.settings.pencil || this.settings.pencil_mobile) {
                html += '<div id="' + this.el.ad_wrapper.replace('#','') + '">' +
                    '<div class="' + this.el.ad_main.replace('.','') + ' container">' +
                        '<div class="' + this.el.ad_strip.replace('.', '') + '">';
                            if (this.settings.expanded_image || this.settings.expanded_image_mobile) {
                                html += '<div class="' + this.el.ad_toggle.replace('.','') + '">' +
                                    '<span class="' + this.el.ad_closed.replace('.','') + '"><i class="fas tnt-chevron-circle-down"></i> <span class="x-pencil-toggle-text">Expand</span></span>' +
                                    '<span class="' + this.el.ad_open.replace('.','') + '"><i class="fas tnt-chevron-circle-up"></i> <span class="x-pencil-toggle-text">Close</span></span>' +
                                '</div>';
                            }
                            html += '<a href="' + this.settings.click_tag + '" target="_blank" rel="noopener">';
                                if (this.settings.pencil) {
                                    html += '<img class="img-responsive hidden-xs" src="' + this.settings.pencil + '" border="0">';
                                }
                                if (this.settings.pencil_mobile) {
                                    html += '<img class="img-responsive visible-xs" src="' + this.settings.pencil_mobile + '" border="0">';
                                }
                            html += '</a>' +
                        '</div>';
                        if (this.settings.expanded_image || this.settings.expanded_image_mobile) {
                            html += '<div class="' + this.el.ad_billboard.replace('.','') + '">' +
                                '<a href="' + this.settings.click_tag + '" target="_blank" rel="noopener">';
                                    if (this.settings.expanded_image) {
                                        html += '<img class="img-responsive hidden-xs" src="' + this.settings.expanded_image + '" border="0">';
                                    }
                                    if (this.settings.expanded_image_mobile) {
                                        html += '<img class="img-responsive visible-xs" src="' + this.settings.expanded_image_mobile + '" border="0">';
                                    }
                                html += '</a>' +
                            '</div>';
                        }
                    html += '</div>' +
                '</div>';
            }
            if (this.settings.tracking_pixel) {
                html += '<div style="display:none;"><img src="' + this.settings.tracking_pixel + '" height="1" width="1" /></div>';
            }
            return html;
        },
        'load': function(settings) {
            this.settings = settings;

            var that = this,
                expandClicked = false;

            $(d).find(p).hide();
            $(d).find(p).parent().prepend(this.ad_html());
            $(d).find(p).parents('.tnt-ads').width('auto');

            var $insertedAd = $(d).find(p).parent().find(this.el.ad_wrapper);

            $insertedAd.find('.tn-pencil-toggle').on('click', function() {
                $insertedAd.toggleClass('in');
                expandClicked = true;
            });

            if (!c.get('tncms-ads-pencil-expand') && this.settings.auto_expand === 'true') {
                var xExpTime = new Date();
                xExpTime.setTime(xExpTime.getTime() + (12 * 60 * 60 * 1000));
                setTimeout(function(){
                    $insertedAd.addClass('in');
                    c.set('tncms-ads-pencil-expand', 1, { path:'/', expires:xExpTime });
                    if (that.settings.auto_expand_close && that.settings.auto_expand_close > 0){
                        var xPencilTimer = parseInt(that.settings.auto_expand_close);
                        if (xPencilTimer < 3 || xPencilTimer > 100) {
                            xPencilTimer = 2;
                        }
                        xPencilTimer = (xPencilTimer * 1000);
                        setTimeout(function(){
                            if (!expandClicked) {
                                $insertedAd.removeClass('in');
                            }
                        }, xPencilTimer);
                    }
                }, 500);
            }
        },
    };

    try {
        if (!i) {
            throw 'Friendly iframe required.';
        }
        $(t.cmds).each(function() {
            TNT_Pencil_Ad.load(this.call());
            return false;
        });
    }
    catch(e){
        if (w.console) {
            w.console.warn(e);
        }
    }
})(
    (window.inDapIF ? true : false),
    (window.inDapIF ? window.frameElement : null),
    (window.inDapIF ? window.parent : window),
    (window.inDapIF ? window.parent.document : document),
    (window.inDapIF ? window.parent.document.body : document.body),
    (window.inDapIF ? window.parent.jQuery : jQuery),
    (window.inDapIF ? window.parent.Cookies : Cookies),
    (window.__TNT_AD || {})
);